import React, { useEffect, useState, Fragment } from 'react';
import { format, parseISO } from 'date-fns';
import { navigate } from 'gatsby-link';
import { toast } from 'react-toastify';

import Layout from '@components/Layout';
import Loader from '@components/Loader';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Card from '@components/Card';
import { makeGetRequest } from '@helpers/requests';
import { CHECKOUT_ORDER } from '@helpers/api';
import { handleError } from '@helpers/errors';
import { graphql, useStaticQuery } from 'gatsby';
import { connect } from 'react-redux';
import queryString from 'query-string';

const Order = ({ id, token }) => {
    const {
        merlinWebsite: {
            websiteId,
            store: {
                currency: { code: currencyCode, symbol: currencySymbol },
            },
        },
    } = useStaticQuery(graphql`
        query {
            merlinWebsite {
                websiteId
                store {
                    currency {
                        code
                        symbol
                    }
                }
            }
        }
    `);
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!id) {
            navigate('/');
            toast.error(
                "This order doesn't seem to exist, contact support if you think this is an error."
            );
            return;
        }

        (async () => {
            try {
                const { data: order } = await makeGetRequest(CHECKOUT_ORDER(id), {
                    websiteId,
                    token,
                });
                setOrder(order);
                setLoading(false);
            } catch (error) {
                navigate('/');
                handleError(error);
            }
        })();
    }, []);

    return (
        <Layout>
            {loading ? (
                <Loader />
            ) : (
                <Grid>
                    <Row center="xs">
                        <Col xs={12}>
                            <h1>We have your order!</h1>
                            <Divider margin={5} />
                        </Col>
                    </Row>
                    <Card>
                        <Card.List>
                            {order.products.map(
                                ({
                                    quantity,
                                    data: { _id, shortName, name, description, price, images },
                                }) => (
                                    <Card.List.Item
                                        key={_id}
                                        title={`${quantity}x ${name}`}
                                        onClick={() => navigate(`/store/${shortName}`)}
                                        avatar={
                                            images && Array.isArray(images) && images.length
                                                ? images[0].url
                                                : ''
                                        }
                                    >
                                        <p>
                                            {description.length > 300
                                                ? `${description.slice(0, 300)}...`
                                                : description}
                                        </p>
                                        <Card.List.Item.Position.Bottom.Right
                                            bold
                                            text={
                                                parseInt(price) === 0
                                                    ? 'Free'
                                                    : `${currencySymbol}${(price / 100).toFixed(2)}`
                                            }
                                        />
                                    </Card.List.Item>
                                )
                            )}
                        </Card.List>
                    </Card>
                    <Divider />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Content>
                                    <Card.List title="General details">
                                        <Card.List.Item label="Id" value={order._id} />
                                        <Card.List.Item
                                            label="Created"
                                            value={format(parseISO(order.meta?.timestamp), 'PPP p')}
                                        />
                                        <Card.List.Item
                                            label="Card Number"
                                            value={
                                                order.stripe &&
                                                `···· ···· ···· ${
                                                    order?.stripe?.card?.last4 ?? ''
                                                } ${
                                                    order?.stripe?.card?.network?.toUpperCase() ??
                                                    ''
                                                }`
                                            }
                                        />
                                        <Card.List.Item
                                            label="Card Expiry"
                                            value={
                                                order.stripe &&
                                                `${
                                                    `${order?.stripe?.card?.exp_month ?? ''}`
                                                        .length === 1
                                                        ? `0${order?.stripe?.card?.exp_month ?? ''}`
                                                        : order?.stripe?.card?.exp_month ?? ''
                                                }/${order?.stripe?.card?.exp_year ?? ''}`
                                            }
                                        />
                                        <Card.List.Item
                                            label="Subtotal"
                                            value={`${currencySymbol}${(
                                                order?.meta?.total / 100 ?? 0
                                            ).toFixed(2)}`}
                                        />
                                        <Card.List.Item
                                            label="Delivery"
                                            value={`${currencySymbol}${(
                                                order?.meta?.shippingPrice / 100 ?? 0
                                            ).toFixed(2)}`}
                                        />
                                        <Card.List.Item
                                            bold
                                            label="Total"
                                            value={
                                                <p>
                                                    <b>{`${currencySymbol}${(
                                                        (order?.meta?.total / 100 ?? 0) +
                                                        (order?.meta?.shippingPrice / 100 ?? 0)
                                                    ).toFixed(2)} ${currencyCode}`}</b>
                                                </p>
                                            }
                                        />
                                    </Card.List>
                                </Card.Content>
                            </Card>
                            <Divider />

                            {(!!order.billing || !!order.shipping) && (
                                <Row>
                                    <Col xs={12} xl={6}>
                                        {!!order.billing && (
                                            <Fragment>
                                                <Card>
                                                    <Card.List title="Billing details">
                                                        <Card.List.Item
                                                            key="billing_added"
                                                            label="Added"
                                                            value={format(
                                                                parseISO(order.billing.added),
                                                                'PPP p'
                                                            )}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_name"
                                                            label="Name"
                                                            value={order.billing.fullName}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_email"
                                                            label="Email"
                                                            value={order.billing.email}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_phone"
                                                            label="Phone Number"
                                                            value={order.billing.phone}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_address_street_one"
                                                            label="Address One"
                                                            value={order.billing.streetOne}
                                                        />
                                                        {!!order.billing.streetTwo && (
                                                            <Card.List.Item
                                                                key="billing_address_street_two"
                                                                label="Address Two"
                                                                value={order.billing.streetTwo}
                                                            />
                                                        )}
                                                        <Card.List.Item
                                                            key="billing_address_postcode"
                                                            label="ZIP Code / Postal Code"
                                                            value={order.billing.postcode}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_city"
                                                            label="City"
                                                            value={order.billing.city}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_county"
                                                            label="County / Province"
                                                            value={order.billing.county}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_country"
                                                            label="Country"
                                                            value={order.billing.country}
                                                        />
                                                    </Card.List>
                                                </Card>
                                                <Divider />
                                            </Fragment>
                                        )}
                                    </Col>
                                    <Col xs={12} xl={6}>
                                        {!!order.shipping && (
                                            <Fragment>
                                                <Card>
                                                    <Card.List title="Shipping details">
                                                        <Card.List.Item
                                                            key="billing_added"
                                                            label="Added"
                                                            value={format(
                                                                parseISO(order.shipping.added),
                                                                'PPP p'
                                                            )}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_name"
                                                            label="Name"
                                                            value={order.shipping.fullName}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_email"
                                                            label="Email"
                                                            value={order.shipping.email}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_phone"
                                                            label="Phone Number"
                                                            value={order.shipping.phone}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_address_street_one"
                                                            label="Address One"
                                                            value={order.shipping.streetOne}
                                                        />
                                                        {!!order.shipping.streetTwo && (
                                                            <Card.List.Item
                                                                key="billing_address_street_two"
                                                                label="Address Two"
                                                                value={order.shipping.streetTwo}
                                                            />
                                                        )}
                                                        <Card.List.Item
                                                            key="billing_address_postcode"
                                                            label="ZIP Code / Postal Code"
                                                            value={order.shipping.postcode}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_city"
                                                            label="City"
                                                            value={order.shipping.city}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_county"
                                                            label="County / Province"
                                                            value={order.shipping.county}
                                                        />
                                                        <Card.List.Item
                                                            key="billing_country"
                                                            label="Country"
                                                            value={order.shipping.country}
                                                        />
                                                    </Card.List>
                                                </Card>
                                                <Divider />
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>

                    <Row center="xs">
                        <Col xs={12}>
                            <Divider margin={5} />
                            <h2>See you again soon!</h2>
                        </Col>
                    </Row>
                </Grid>
            )}
        </Layout>
    );
};

export default connect((state, ownProps) => {
    const params = queryString.parse(ownProps.location.search);

    return {
        token: params.token,
    };
})(Order);
